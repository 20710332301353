export class ValidationError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class ItemNotFoundError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class FetchError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class FetchResponseError extends FetchError {
  public readonly method: string;
  public readonly url: string;
  public readonly response: Response;

  constructor({
    method,
    url,
    response,
  }: {
    method: string;
    url: string;
    response: Response;
  }) {
    super(`Failed to ${method} ${url}, status: ${response.status}`);

    this.method = method;
    this.url = url;
    this.response = response;
  }
}

export class FetchException extends FetchError {
  constructor({
    message,
    method,
    url,
  }: {
    message: string;
    method: string;
    url: string;
  }) {
    super(`Failed to ${method} ${url}, error: ${message}`);
  }
}
