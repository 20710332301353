'use client';
import classNames from 'classnames';
import { GoChevronDown } from 'react-icons/go';
import { ReactNode, useRef } from 'react';
import { Button } from './button';

export type NativeSelectOption = {
  value: string;
  shortLabel?: ReactNode;
  longLabel: ReactNode;
};

type Props = {
  className?: string;
  buttonClassName?: string;
  optionClassName?: string;
  ariaLabel: string;
  value?: string;
  placeholder?: ReactNode;
  options: NativeSelectOption[];
  ghost?: boolean;
  tight?: boolean;
  round?: boolean;
  secondary?: boolean;
  chevron?: boolean;
  onChange: (value: string) => void;
};

const padding = 'px-0.5 xs:px-1';

export function NativeSelect({
  className,
  buttonClassName,
  optionClassName,
  ariaLabel,
  value,
  placeholder,
  options,
  ghost,
  tight,
  round,
  secondary,
  chevron = true,
  onChange,
}: Props) {
  const selectRef = useRef<HTMLSelectElement>(null);

  const selectedOption = options.find((o) => o.value === value);

  return (
    <div
      className={classNames(
        'relative',
        'flex items-center',
        padding,
        'rounded-md',
        'focus-within:ring-2 focus-within:ring-blue-500',
        className,
      )}
    >
      <select
        ref={selectRef}
        aria-label={ariaLabel}
        value={value}
        className={classNames(
          'appearance-none',
          'absolute inset-x-0 opacity-0',
          'py-0.5 xs:py-1',
          padding,
          'bg-base-100 border-none',
        )}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map(({ value, longLabel }) => (
          <option key={value} value={value} className={optionClassName}>
            {longLabel}
          </option>
        ))}
      </select>
      <Button
        className={classNames('flex', buttonClassName)}
        round={round}
        ghost={ghost}
        tight={tight}
        secondary={secondary}
        tabIndex={-1}
        onClick={() => selectRef.current?.focus()}
      >
        <div className="flex items-center gap-0.5 xs:gap-1">
          <div>{selectedOption ? selectedOption.shortLabel : placeholder}</div>
          {chevron && <GoChevronDown />}
        </div>
      </Button>
    </div>
  );
}
