import classNames from 'classnames';

type Props = {
  checked?: boolean;
  label: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function Toggle({ checked, label, disabled, onChange }: Props) {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <span className="me-3">{label}</span>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="sr-only peer"
        disabled={disabled}
      />
      <div
        className={classNames(
          'peer relative',
          'w-11 h-6',
          'bg-gray-200 rounded-full',
          'peer-checked:bg-primary-base peer-checked:after:border-white',
          'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full',
          'peer-focus:outline-hidden peer-focus:ring-4 peer-focus:ring-primary-base-shade',
          'dark:bg-gray-700 dark:border-gray-600',
          "after:content-[''] after:h-5 after:w-5 after:absolute after:top-[2px] after:start-[2px]",
          'after:bg-white after:border after:border-gray-300 after:rounded-full',
          'after:transition-all',
        )}
      ></div>
    </label>
  );
}
