import { ReactNode } from 'react';
import { GoChevronDown } from 'react-icons/go';
import { Button } from './button';
import { Dropdown } from './dropdown';
import classNames from 'classnames';

type Props = {
  className?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  buttonChildren: ReactNode;
  dropdownChildren: ReactNode;
  buttonDisabled?: boolean;
  onClick: () => void;
};

export function DropdownButton({
  className,
  buttonClassName,
  dropdownClassName,
  buttonChildren,
  dropdownChildren,
  buttonDisabled,
  onClick,
}: Props) {
  return (
    <div className={classNames('flex items-stretch', className)}>
      <Button
        className={classNames('flex-1 rounded-s-md', buttonClassName)}
        disabled={buttonDisabled}
        round={false}
        onClick={onClick}
      >
        {buttonChildren}
      </Button>
      <Dropdown
        round={false}
        tight
        container={<GoChevronDown />}
        ariaLabel={''}
        placement={'right'}
        buttonClassName={classNames(
          'h-full px-1.5 rounded-e-md',
          dropdownClassName,
        )}
      >
        {dropdownChildren}
      </Dropdown>
    </div>
  );
}
