export function groupArrayBy<T, K extends keyof T>({
  key,
  array,
}: {
  array: (T & Record<K, string>)[];
  key: K;
}): Record<string, T[]> {
  return array.reduce(
    (acc, item) => {
      const keyValue = item[key];

      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }

      acc[keyValue].push(item);

      return acc;
    },
    {} as Record<string, T[]>,
  );
}
